import React from "react"

// import Layout from "../components/layout"
import SEO from "../components/seo"

const TermsPage = () => (
  <>
    <SEO title="Terms Of Use" />
    <div className={"container"}>
      <div className={"content"}>
        <div className={"title"}>
          <h1>Terms Of Use</h1>
        </div>

        <p>
          <strong>Last Updated 07 June 2019</strong>
        </p>

        <h3>
          <strong>1. Agreement to Terms</strong>
        </h3>
        <p>
          1.1 These Terms and Conditions constitute a legally binding agreement
          made between you, whether personally or on behalf of an entity (
          <strong>you</strong>), and <strong>HiStaff</strong>, doing business as
          <strong> HiStaff</strong> <strong>(https://histaff.io) </strong>
          website as well as any related applications (the <strong>Site</strong>
          ). &nbsp;
        </p>
        <p>
          The Site provides the following services: (<strong>Services</strong>).
          You agree that by accessing the Site and/or Services, you have read,
          understood, and agree to be bound by all of these Terms and
          Conditions. &nbsp;
        </p>
        <p>
          <strong>
            If you do not agree with all of these Terms and Conditions, then you
            are prohibited from using the Site and Services and you must
            discontinue use immediately
          </strong>
          . We recommend that you print a copy of these Terms and Conditions for
          future reference. &nbsp;
        </p>
        <p>
          1.2 The supplemental policies set out in Section 1.7 below, as well as
          any supplemental terms and condition or documents that may be posted
          on the Site from time to time, are expressly incorporated by
          reference. &nbsp;
        </p>
        <p>
          1.3 We may make changes to these Terms and Conditions at any time. The
          updated version of these Terms and Conditions will be indicated by an
          updated &acirc;&euro;&oelig;Revised&acirc;&euro; date and the updated
          version will be effective as soon as it is accessible. You are
          responsible for reviewing these Terms and Conditions to stay informed
          of updates. Your continued use of the Site represents that you have
          accepted such changes. &nbsp;
        </p>
        <p>
          1.4 We may update or change the Site from time to time to reflect
          changes to our products, our users' needs and/or our business
          priorities. &nbsp;
        </p>
        <p>
          1.5 Our site is directed to people residing in England. The
          information provided on the Site is not intended for distribution to
          or use by any person or entity in any jurisdiction or country where
          such distribution or use would be contrary to law or regulation or
          which would subject us to any registration requirement within such
          jurisdiction or country. &nbsp;
        </p>
        <p>
          1.6 The Site is intended for users who are at least 18 years old. If
          you are under the age of 18, you are not permitted to register for the
          Site or use the Services without parental permission.
        </p>
        <p>
          1.7 Additional policies which also apply to your use of the Site
          include: &nbsp;
        </p>
        <p>
          <strong>&mdash; </strong>Our Privacy Notice
          https://histaff.io/privacy, which sets out the terms on which we
          process any personal data we collect from you, or that you provide to
          us. By using the Site, you consent to such processing and you warrant
          that all data provided by you is accurate. &nbsp;
        </p>
        <p>
          <strong>&mdash; </strong>Our Acceptable Use Policy contact@histaff.io,
          which sets out the permitted uses and prohibited uses of the Site.
          When using the Site, you must comply with this Acceptable Use Policy.
          &nbsp;
        </p>
        <p>
          <strong>&mdash; </strong>Our Cookie Policy https://app.histaff.io,
          which sets out information about the cookies on the Site.
        </p>

        <h3>
          <strong>2. </strong>&nbsp;<strong>Acceptable Use </strong>{" "}
        </h3>
        <p>
          2.1 Our full Acceptable Use Policy contact@histaff.io, sets out all
          the permitted uses and prohibited uses of this site. &nbsp;
        </p>
        <p>
          2.2 You may not access or use the Site for any purpose other than that
          for which we make the site and our services available. The Site may
          not be used in connection with any commercial endeavors except those
          that are specifically endorsed or approved by us. &nbsp;
        </p>
        <p>2.3 As a user of this Site, you agree not to: &nbsp;</p>
        <p>
          <strong>&mdash; </strong>Falsely imply a relationship with us or
          another company with whom you do not have a relationship{" "}
        </p>

        <h3>3. Information you provide to us</h3>
        <p>
          3.1 You represent and warrant that: (a) all registration information
          you submit will be true, accurate, current, and complete and relate to
          you and not a third party; (b) you will maintain the accuracy of such
          information and promptly update such information as necessary; (c) you
          will keep your password confidential and will be responsible for all
          use of your password and account; (d) you have the legal capacity and
          you agree to comply with these Terms and Conditions; and (e) you are
          not a minor in the jurisdiction in which you reside, or if a minor,
          you have received parental permission to use the Site. &nbsp;
        </p>
        <p>
          If you know or suspect that anyone other than you knows your user
          information (such as an identification code or user name) and/or
          password you must promptly notify us at contact@hitstaff.io.
        </p>
        <p>
          3.2 If you provide any information that is untrue, inaccurate, not
          current or incomplete, we may suspend or terminate your account. We
          may remove or change a user name you select if we determine that such
          user name is inappropriate. &nbsp;
        </p>

        <h3>
          <strong>4.</strong> <strong> </strong>
          <strong>Content you provide to us</strong>
        </h3>
        <p>
          4.1 There may be opportunities for you to post content to the Site or
          send feedback to us (<strong>User Content</strong>). You understand
          and agree that your User Content may be viewed by other users on the
          Site, and that they may be able to see who has posted that User
          Content.{" "}
        </p>
        <p>
          4.2{" "}
          <em>
            You further agree that we can use your User Content for any other
            purposes whatsoever in perpetuity without payment to you, and
            combine your User Content with other content for use within the Site
            and otherwise. We do not have to attribute your User Content to you
          </em>
          .{" "}
          <em>
            When you upload or post content to our site, you grant us the
            following rights to use that content:
          </em>{" "}
          &nbsp;
        </p>
        <p>
          4.3 In posting User Content, including reviews or making contact with
          other users of the Site you shall comply with our Acceptable Use
          Policy contact@histaff.io. &nbsp;
        </p>
        <p>
          4.4 You warrant that any User Content does comply with our Acceptable
          Use Policy, and you will be liable to us and indemnify us for any
          breach of that warranty. This means you will be responsible for any
          loss or damage we suffer as a result of your breach of this warranty.
        </p>
        <p>
          4.5 We have the right to remove any User Content you put on the Site
          if, in our opinion, such User Content does not comply with the
          Acceptable Use Policy. &nbsp;
        </p>
        <p>
          4.6 We are not responsible and accept no liability for any User
          Content including any such content that contains incorrect information
          or is defamatory or loss of User Content. We accept no obligation to
          screen, edit or monitor any User Content but we reserve the right to
          remove, screen and/or edit any User Content without notice and at any
          time. User Content has not been verified or approved by us and the
          views expressed by other users on the Site do not represent our views
          or values
        </p>
        <p>
          4.7 If you wish to complain about User Content uploaded by other users
          please contact us at contact@hitstaff.io or use the take down or
          report button. &nbsp;&nbsp;&nbsp;
        </p>

        <h3>5. Our content</h3>
        <p>
          5.1 Unless otherwise indicated, the Site and Services including source
          code, databases, functionality, software, website designs, audio,
          video, text, photographs, and graphics on the Site (
          <strong>Our Content</strong>) are owned or licensed to us, and are
          protected by copyright and trade mark laws. &nbsp;
        </p>
        <p>
          5.2 Except as expressly provided in these Terms and Conditions, no
          part of the Site, Services or Our Content may be copied, reproduced,
          aggregated, republished, uploaded, posted, publicly displayed,
          encoded, translated, transmitted, distributed, sold, licensed, or
          otherwise exploited for any commercial purpose whatsoever, without our
          express prior written permission.
        </p>
        <p>
          5.3 Provided that you are eligible to use the Site, you are granted a
          limited licence to access and use the Site and Our Content and to
          download or print a copy of any portion of the Content to which you
          have properly gained access solely for your personal, non-commercial
          use. &nbsp;
        </p>
        <p>
          5.4 You shall not (a) try to gain unauthorised access to the Site or
          any networks, servers or computer systems connected to the Site;
          and/or (b) make for any purpose including error correction, any
          modifications, adaptions, additions or enhancements to the Site or Our
          Content, including the modification of the paper or digital copies you
          may have downloaded.
        </p>
        <p>
          5.5 We shall (a) prepare the Site and Our Content with reasonable
          skill and care; and (b) use industry standard virus detection software
          to try to block the uploading of content to the Site that contains
          viruses. &nbsp;
        </p>
        <p>
          5.6 The content on the Site is provided for general information only.
          It is not intended to amount to advice on which you should rely. You
          must obtain professional or specialist advice before taking, or
          refraining from taking, any action on the basis of the content on the
          Site. &nbsp;
        </p>
        <p>
          5.7 Although we make reasonable efforts to update the information on
          our site, we make no representations, warranties or guarantees,
          whether express or implied, that Our Content on the Site is accurate,
          complete or up to date.{" "}
        </p>

        <h3>
          <strong>6. Link to third party content</strong>
        </h3>
        <p>
          6.1 The Site may contain links to websites or applications operated by
          third parties.We do not have any influence or control over any such
          third party websites or applications or the third party operator. We
          are not responsible for and do not endorse any third party websites or
          applications or their availability or content.
        </p>
        <p>
          6.2 We accept no responsibility for adverts contained within the Site.
          If you agree to purchase goods and/or services from any third party
          who advertises in the Site, you do so at your own risk. The
          advertiser, and not us, is responsible for such goods and/or services
          and if you have any questions or complaints in relation to them, you
          should contact the advertiser.{" "}
        </p>

        <h3>
          <strong>7. </strong>&nbsp;<strong> </strong>
          <strong>Site Management</strong>
        </h3>
        <p>
          7.1 We reserve the right at our sole discretion, to (1) monitor the
          Site for breaches of these Terms and Conditions; (2) take appropriate
          legal action against anyone in breach of applicable laws or these
          Terms and Conditions; (3) refuse, restrict access to or availability
          of, or disable (to the extent technologically feasible) any of your
          Contributions; (4) remove from the Site or otherwise disable all files
          and content that are excessive in size or are in any way a burden to
          our systems; and (5) otherwise manage the Site in a manner designed to
          protect our rights and property and to facilitate the proper
          functioning of the Site and Services. &nbsp;
        </p>
        <p>
          7.2 We do not guarantee that the Site will be secure or free from bugs
          or viruses.
        </p>
        <p>
          7.3 You are responsible for configuring your information technology,
          computer programs and platform to access the Site and you should use
          your own virus protection software. &nbsp;
        </p>

        <h3>
          <strong>8.</strong> <strong> </strong>
          <strong>Modifications to and availability of the Site</strong>
        </h3>
        <p>
          8.1 We reserve the right to change, modify, or remove the contents of
          the Site at any time or for any reason at our sole discretion without
          notice. We also reserve the right to modify or discontinue all or part
          of the Services without notice at any time. &nbsp;
        </p>
        <p>
          8.2 We cannot guarantee the Site and Services will be available at all
          times. We may experience hardware, software, or other problems or need
          to perform maintenance related to the Site, resulting in
          interruptions, delays, or errors. You agree that we have no liability
          whatsoever for any loss, damage, or inconvenience caused by your
          inability to access or use the Site or Services during any downtime or
          discontinuance of the Site or Services.We are not obliged to maintain
          and support the Site or Services or to supply any corrections,
          updates, or releases.
        </p>
        <p>
          8.3 There may be information on the Site that contains typographical
          errors, inaccuracies, or omissions that may relate to the Services,
          including descriptions, pricing, availability, and various other
          information. We reserve the right to correct any errors, inaccuracies,
          or omissions and to change or update the information at any time,
          without prior notice. &nbsp;
        </p>

        <h3>
          <strong>9.</strong> <strong> </strong>
          <strong>Disclaimer/Limitation of Liability</strong>
        </h3>
        <p>
          9.1 The Site and Services are provided on an as-is and as-available
          basis. You agree that your use of the Site and/or Services will be at
          your sole risk except as expressly set out in these Terms and
          Conditions. All warranties, terms, conditions and undertakings,
          express or implied (including by statute, custom or usage, a course of
          dealing, or common law) in connection with the Site and Services and
          your use thereof including, without limitation, the implied warranties
          of satisfactory quality, fitness for a particular purpose and
          non-infringement are excluded to the fullest extent permitted by
          applicable law. &nbsp;
        </p>
        <p>
          We make no warranties or representations about the accuracy or
          completeness of the Site&acirc;&euro;&trade;s content and are not
          liable for any (1) errors or omissions in content: (2) any
          unauthorized access to or use of our servers and/or any and all
          personal information and/or financial information stored on our
          server; (3) any interruption or cessation of transmission to or from
          the site or services; and/or (4) any bugs, viruses, trojan horses, or
          the like which may be transmitted to or through the site by any third
          party. We will not be responsible for any delay or failure to comply
          with our obligations under these Terms and Conditions if such delay or
          failure is caused by an event beyond our reasonable control.
        </p>
        <p>9.2 Our responsibility for loss or damage suffered by you:</p>
        <p>
          <strong>Whether you are a consumer or a business user:</strong>
        </p>
        <p>
          <strong>&mdash; </strong>We do not exclude or limit in any way our
          liability to you where it would be unlawful to do so. This includes
          liability for death or personal injury caused by our negligence or the
          negligence of our employees, agents or subcontractors and for fraud or
          fraudulent misrepresentation. &nbsp;
        </p>
        <p>
          <strong>If you are a business user</strong>:
        </p>
        <p>
          We will not be liable to you for any loss or damage, whether in
          contract, tort (including negligence), breach of statutory duty, or
          otherwise, even if foreseeable, arising under or in connection with:
        </p>
        <p>
          <strong>&mdash; </strong>use of, or inability to use, our
          Site/Services; or{" "}
        </p>
        <p>
          <strong>&mdash; </strong>use of or reliance on any content displayed
          on our Site.{" "}
        </p>
        <p>In particular, we will not be liable for:</p>
        <p>
          <strong>&mdash; </strong>loss of profits, sales, business, or revenue;{" "}
        </p>
        <p>
          <strong>&mdash; </strong>business interruption;{" "}
        </p>
        <p>
          <strong>&mdash; </strong>loss of anticipated savings;{" "}
        </p>
        <p>
          <strong>&mdash; </strong>loss of business opportunity, goodwill or
          reputation; or{" "}
        </p>
        <p>
          <strong>&mdash; </strong>any indirect or consequential loss or damage.
          &nbsp;
        </p>
        <p>
          <strong>If you are a consumer user:</strong>
        </p>
        <p>
          <strong>&mdash; </strong>Please note that we only provide our Site for
          domestic and private use. You agree not to use our Site for any
          commercial or business purposes, and we have no liability to you for
          any loss of profit, loss of business, business interruption, or loss
          of business opportunity.
        </p>
        <p>
          <strong>&mdash; </strong>If defective digital content that we have
          supplied, damages a device or digital content belonging to you and
          this is caused by our failure to use reasonable care and skill, we
          will either repair the damage or pay you compensation.{" "}
        </p>
        <p>
          <strong>&mdash; </strong>You have legal rights in relation to goods
          that are faulty or not as described. Advice about your legal rights is
          available from your local Citizens' Advice Bureau or Trading Standards
          office. Nothing in these Terms and Conditions will affect these legal
          rights. &nbsp;&nbsp;&nbsp;
        </p>

        <h3>
          <strong>10. </strong>&nbsp;<strong> </strong>
          <strong>Term and Termination</strong>
        </h3>
        <p>
          10.1 These Terms and Conditions shall remain in full force and effect
          while you use the Site or Services or are otherwise a user of the
          Site, as applicable. You may terminate your use or participation at
          any time, for any reason, by following the instructions for
          terminating user accounts in your account settings, if available, or
          by contacting us at contact@hitstaff.io. &nbsp;
        </p>
        <p>
          10.2 Without limiting any other provision of these Terms and
          Conditions, we reserve the right to, in our sole discretion and
          without notice or liability, deny access to and use of the Site and
          the Services (including blocking certain IP addresses), to any person
          for any reason including without limitation for breach of any
          representation, warranty or covenant contained in these Terms and
          Conditions or of any applicable law or regulation. &nbsp;
        </p>
        <p>
          If we determine, in our sole discretion, that your use of the
          Site/Services is in breach of these Terms and Conditions or of any
          applicable law or regulation, we may terminate your use or
          participation in the Site and the Services or delete your profile and
          any content or information that you posted at any time, without
          warning, in our sole discretion. &nbsp;
        </p>
        <p>
          10.3 If we terminate or suspend your account for any reason set out in
          this Section 9, you are prohibited from registering and creating a new
          account under your name, a fake or borrowed name, or the name of any
          third party, even if you may be acting on behalf of the third party.
          In addition to terminating or suspending your account, we reserve the
          right to take appropriate legal action, including without limitation
          pursuing civil, criminal, and injunctive redress.{" "}
        </p>

        <h3>
          <strong>11.</strong> <strong>General</strong>
        </h3>
        <p>
          11.1 Visiting the Site, sending us emails, and completing online forms
          constitute electronic communications. You consent to receive
          electronic communications and you agree that all agreements, notices,
          disclosures, and other communications we provide to you
          electronically, via email and on the Site, satisfy any legal
          requirement that such communication be in writing. &nbsp;
        </p>
        <p>
          <strong>
            You hereby agree to the use of electronic signatures, contracts,
            orders and other records and to electronic delivery of notices,
            policies and records of transactions initiated or completed by us or
            via the Site.
          </strong>{" "}
          You hereby waive any rights or requirements under any statutes,
          regulations, rules, ordinances or other laws in any jurisdiction which
          require an original signature or delivery or retention of
          non-electronic records, or to payments or the granting of credits by
          other than electronic means. &nbsp;
        </p>
        <p>
          11.2 These Terms and Conditions and any policies or operating rules
          posted by us on the Site or in respect to the Services constitute the
          entire agreement and understanding between you and us. &nbsp;
        </p>
        <p>
          11.3 Our failure to exercise or enforce any right or provision of
          these Terms and Conditions shall not operate as a waiver of such right
          or provision. &nbsp;
        </p>
        <p>
          11.4 We may assign any or all of our rights and obligations to others
          at any time. &nbsp;
        </p>
        <p>
          11.5 We shall not be responsible or liable for any loss, damage, delay
          or failure to act caused by any cause beyond our reasonable control.
          &nbsp;
        </p>
        <p>
          11.6 If any provision or part of a provision of these Terms and
          Conditions is unlawful, void or unenforceable, that provision or part
          of the provision is deemed severable from these Terms and Conditions
          and does not affect the validity and enforceability of any remaining
          provisions. &nbsp;
        </p>
        <p>
          11.7 There is no joint venture, partnership, employment or agency
          relationship created between you and us as a result of these Terms and
          Conditions or use of the Site or Services.
        </p>
        <p>
          11.8{" "}
          <strong>
            <em>For consumers only </em>
          </strong>
          <em>
            &nbsp;- Please note that these Terms and Conditions, their subject
            matter and their formation, are governed by English law. You and we
            both agree that the courts of England and Wales will have exclusive
            jurisdiction expect that if you are a resident of Northern Ireland
            you may also bring proceedings in Northern Ireland, and if you are
            resident of Scotland, you may also bring proceedings in Scotland. If
            you have any complaint or wish to raise a dispute under these Terms
            and Conditions or otherwise in relation to the Site please follow
            this link
          </em>{" "}
          <a href="http://ec.europa.eu/odr">
            <em>http://ec.europa.eu/odr</em>
          </a>
          &nbsp;
        </p>
        <p>
          11.9{" "}
          <strong>
            <em>For business users only</em>
          </strong>
          <em>
            {" "}
            - If you are a business user, these Terms and Conditions, their
            subject matter and their formation (and any non-contractual disputes
            or claims) are governed by English Law. We both agree to the
            exclusive jurisdiction of the courts of England and Wales
          </em>
          . &nbsp;
        </p>
        <p>
          11.10 A person who is not a party to these Terms and Conditions shall
          have no right under the Contracts (Rights of Third Parties) Act 1999
          to enforce any term of these Terms and Conditions.
        </p>
        <p>
          11.11 In order to resolve a complaint regarding the Services or to
          receive further information regarding use of the Services, please
          contact us by email at contact@hitstaff.io
        </p>
      </div>
    </div>
  </>
)

export default TermsPage
